body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.traffic-light {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 320px;
  background-color: #000;
  border-radius: 10px;
  margin: 20px;
  padding: 1px;;
}

.light {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 10px;
}
.light > div {
  position: relative;
  top: 5px;
  width: 40px;
  height: 20px;
  border-radius: 50%;
  margin: 20px 20px 20px 20px;
}

.red {
  background-color: rgb(226, 6, 19);
}

/* .red.on {
  box-shadow: 0px 0px 40px 10px rgb(226, 6, 19);
} */
.red > div {
  background-color: rgb(232, 61, 71);
}

.yellow {
  background-color: rgb(255, 191 , 0);
}
/* .yellow.on {
  box-shadow: 0px 0px 40px 10px rgb(255, 191 , 0);
} */
.yellow > div {
  background-color: rgb(255, 216, 99);
}

.green {
  background-color: rgb(0, 149, 64);
}
/* .green.on {
  box-shadow: 0px 0px 40px 10px rgb(0, 149, 64);
} */
.green > div {
  background-color: rgb(23, 159, 81);
}

.off {
  opacity: 10%;
}

.about {
  margin: 1em;
}
